import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import NewsHeader from "../components/newsHeader"
// import NewsPagination from "../components/newsPagination"

const NewsEvents = ({ data }) => {

  const seoContent = data.pageContent.frontmatter;
  const pageContent = data.pageContent.frontmatter.hero;
  const categories = data.categories.edges;
  // const posts = data.articles.edges

  return (
    <Layout classes="news">
      <Seo title={pageContent.title} description={pageContent.caption} />
      <NewsHeader pageContent={pageContent} categories={categories} pageTitle={seoContent.title} />
      {/* <NewsPagination posts={posts} /> */}
    </Layout>
  );
}

export const pageQuery = graphql`{
  pageContent: markdownRemark(frontmatter: {template_key: {eq: "news-&-events"}}) {
    frontmatter {
      title
      hero {
        title
        caption
      }
    }
  }
  # articles: allMarkdownRemark(
  #   sort: {fields: [frontmatter___data_date], order: DESC}
  #   filter: {frontmatter: {template_key: {eq: "article"}}}
  # ) {
  #   edges {
  #     node {
  #       fields {
  #         slug
  #       }
  #       frontmatter {
  #         template_key
  #         date
  #         title
  #         categories
  #         author
  #         caption
  #       }
  #     }
  #   }
  # }
  categories: allMarkdownRemark(
    filter: {frontmatter: {template_key: {eq: "category"}}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
}
`

export default NewsEvents
